import React from 'react'

import wave from '../../static/wave-line.svg'

import Layout from '../components/Layout.js'
import Posts from '../components/Posts.js'

export default ({ data, pageContext }) => {
  const { posts, categoryName } = pageContext

  return (
    <Layout>
      <h1 class="post__title center-text category__title negative-margin">
        {categoryName}
      </h1>
      <img src={wave} alt="waves" class="post__line" />
      <Posts data={posts} />
    </Layout>
  )
}
